
.btn {
  @apply w-full max-w-fit lg:w-auto bg-gray-100 text-black text-base font-bold uppercase transition-all duration-200 border border-transparent py-2 px-4 inline-flex items-center justify-between disabled:opacity-50;

  svg {
    @apply w-auto h-4 ml-3 duration-200;
  }

  span {
    @apply inline-flex pt-1 text-sm;
  }

  &.btn-primary {
    @apply bg-primary-500 text-white border-primary-500 hover:text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:ring-offset-primary-700 focus:bg-primary-500;

    svg {
      @apply text-white;
    }
  }

  &.btn-secondary {
    @apply bg-secondary-500 text-white border-secondary-500 hover:text-white hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 focus:ring-offset-secondary-700 focus:bg-secondary-500;

    svg {
      @apply text-white;
    }
  }

  &.btn-gray {
    @apply bg-gray-100 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:ring-offset-gray-500 focus:bg-gray-300;
  }

  &.btn-red {
    @apply bg-red-500 text-white hover:bg-red-500;

    &.table {
      @apply py-1.5 px-3;
    }
  }

  &.btn-sm {
    @apply text-sm px-3;

    svg {
      @apply ml-2;
    }
  }
}
