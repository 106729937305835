/* The switch - the box around the slider */

.cm-switch-container {
  @include var(border-bottom-style, border-style);
  @include var(border-bottom-width, border-width);

  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }
}

.cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.cm-list-input:checked + .cm-list-label .slider {
  @apply bg-primary;
}

.cm-list-input.half-checked:checked + .cm-list-label .slider {
  @apply bg-primary opacity-50;

  &::before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

.cm-list-input.only-required + .cm-list-label .slider {
  @apply bg-primary opacity-75;

  &::before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}

.cm-list-input.required:checked + .cm-list-label .slider {
  @apply bg-primary opacity-75 cursor-not-allowed;
}

.cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}

.cm-list-title {
  font-size: 0.9em;
  font-weight: 600;
}

.cm-list-description {
  @include var(color, dark3);
  font-size: 0.9em;
  padding-top: 4px;
}

.cm-list-label {
  /* The slider */

  .cm-switch {
    position: absolute;
    left: 0;
  }

  .slider {
    @apply inline-block bg-gray-200 absolute cursor-pointer top-0 left-0 right-0 bottom-0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 50px;
  }

  .slider::before {
    @apply bg-white absolute;

    content: '';
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 5px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 30px;
  }

  .slider.round::before {
    border-radius: 50%;
  }

  input:checked + .slider::before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

.cm-list-input:checked + .cm-list-label .slider::before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
