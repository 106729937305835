#slider {
  @apply relative w-full h-full mx-auto;

  canvas {
    @apply absolute top-1/2 left-1/2 z-10 w-[150%] h-[150%] transform -translate-x-1/2 -translate-y-1/2;
    z-index: 2;
  }

  img {
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 0;
  }

  &:before {
    @apply content-[''] absolute top-0 left-0 w-full h-full bg-black-900 z-20 transition opacity-0;
  }

  &:after {
    @apply z-20 transition opacity-0;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    pointer-events: none;
    border-radius: 50%;
    background: white;
  }

  &.loading {
    @apply before:opacity-100 after:animate-pulse;
  }
}

.slider-inner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 5;
}

#pagination {
  @apply absolute top-1/2 right-6 transform -translate-y-1/2 pt-12 lg:pt-0;

  button {
    @apply relative block bg-white rounded-full p-0 border-0 w-2 h-2 lg:w-3 lg:h-3 opacity-20 transition delay-500;
    appearance: none;
    margin: 30px 0;
    cursor: pointer;
    outline: none;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      opacity: 1;

      &:before {
        width: 300%;
        height: 300%;
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      opacity: 0;
      transition-delay: 0.5s;
      transition: opacity 0.8s ease-in-out, width 0.8s ease-in-out,
        height 0.8s ease-in-out;
    }
  }
}

/* Page Loader */

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}
