@import './klaro/modal';
@import './klaro/vars';

.klaro {

  $btn-margin-right: 0.5em;

  @include var(font-family, font-family);
  @include var(font-size, font-size);

  // buttons use their own font-family setting so we need to override it explicitly...
  button {
    @include var(font-family, font-family);
    @include var(font-size, font-size);
  }

  &.cm-as-context-notice {
    height: 100%;
    padding-bottom: map-get($cm-space, md);
    padding-top: map-get($cm-space, md);
  }

  .cookie-modal,
  .context-notice,
  .cookie-notice {
    @import './klaro/switch';

    a {
      @apply text-primary no-underline
    }

    p,
    strong,
    h1,
    h2,
    ul,
    li {
      @apply text-gray-800;
    }

    p,
    h1,
    h2,
    ul,
    li {
      @apply block text-left m-0 p-0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include var(font-family, title-font-family);
    }

    .cm-link {
      vertical-align: middle;
    }

    .cm-btn {
      @apply w-full lg:w-auto leading-tight bg-gray-100 text-black text-sm font-bold uppercase transition-all duration-200 border border-transparent py-1 px-3 inline-flex items-center justify-center disabled:opacity-50;

      &:disabled {
        opacity: 0.5;
      }

      &.cm-btn-close {
        @include var(background-color, light2);
      }

      &.cm-btn-success {
        @apply bg-primary text-white hover:bg-black;
      }

      &.cm-btn-success-var {
        @include var(background-color, green2);
      }

      &.cm-btn-info {
        @apply bg-gray-100 text-black hover:bg-gray-100;
      }
    }
  }

  .context-notice {
    @include var(border-radius, border-radius);
    @include var(border-style, border-style);
    @include var(border-width, border-width);
    @include var(border-color, light2);
    @include var(background-color, light1);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: map-get($cm-space, md);
    height: 100%;

    &.cm-dark {
      @include var(background-color, dark1);
      @include var(border-color, dark2);

      p {
        @include var(color, light1);

        a {
          @include var(color, blue2);
        }
      }
    }

    p {
      @include var(color, dark1);

      flex-grow: 0;
      text-align: center;
      padding-top: 0;
      margin-top: 0;

      a {
        @include var(color, green2);
      }
    }

    p.cm-buttons {
      margin-top: map-get($cm-space, md);
    }
  }

  .cookie-modal {
    @apply fixed w-full h-full top-0 left-0;
    overflow: hidden;
    z-index: 1000;

    &.cm-embedded {
      position: relative;
      height: inherit;
      width: inherit;
      left: inherit;
      right: inherit;
      z-index: 0;

      .cm-modal.cm-klaro {
        position: relative;
        transform: none;
      }
    }

    .cm-bg {
      @apply bg-gray-900/70 fixed w-full h-full top-0 left-0;
    }

    .cm-modal {
      &.cm-klaro {
        @include modal(660px, 640px);
      }

      .hide {
        border-style: none;
        background: none;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;

        svg {
          stroke: black;
        }
      }

      .cm-footer {
        @apply pt-8 px-6 pb-8 md:px-8;

        &-buttons {
          @apply inline-block sm:flex;
          flex-flow: row;
          justify-content: space-between;
        }

        .cm-powered-by {
          font-size: 0.8em;
          padding-top: 4px;
          text-align: right;
          padding-right: 8px;

          a {
            @include var(color, dark2);
          }
        }
      }

      .cm-header {
        @apply pt-8 px-6 pb-8 md:px-8;

        h1 {
          @apply text-xl font-headline font-semibold text-primary transition-colors duration-200 mb-4;

          &.title {
            padding-right: 20px;
          }
        }

        p > span {
          @apply text-sm;
        }
      }

      .cm-body {
        @apply px-6 md:px-8;

        ul {
          display: block;
        }

        span {
          display: inline-block;
          width: auto;
        }

        ul.cm-services,
        ul.cm-purposes {
          @apply p-0 m-0 space-y-6;

          li.cm-purpose {
            .cm-services {
              .cm-caret {
                @include var(color, light3);
              }

              .cm-content {
                margin-left: -40px;
                display: none;

                &.expanded {
                  margin-top: 10px;
                  display: block;
                }
              }
            }
          }

          li.cm-service,
          li.cm-purpose {
            position: relative;
            line-height: 20px;
            vertical-align: middle;
            padding-left: 60px;
            min-height: 40px;

            p {
              margin-top: 0;
            }

            p.purposes {
              @include var(color, light3);
              font-size: 0.8em;
            }

            &.cm-toggle-all {
              @apply pt-6 mt-8 border-t border-gray-300;
            }

            span.cm-list-title {
              font-weight: 600;
            }

            span.cm-opt-out,
            span.cm-required {
              @include var(color, dark2);
              padding-left: 0.2em;
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }

  .cookie-notice:not(.cookie-modal-notice) {
    @apply bg-white fixed w-full lg:max-w-3xl bottom-0 right-0;
    z-index: 999;

    @media (min-width: $cm-desktop) {
      box-shadow: $cm-box-shadow-dark-md;
    }

    @media (max-width: ($cm-desktop - 1)) {
      border-style: none;
      border-radius: 0;
    }

    &.cn-embedded {
      position: relative;
      height: inherit;
      width: inherit;
      left: inherit;
      right: inherit;
      bottom: inherit;
      z-index: 0;

      .cn-body {
        padding-top: 0.5em;
      }
    }

    .cn-body {
      @apply mb-0 mr-0 bottom-0 pt-8 px-6 pb-8 md:px-8 space-y-4;

      p {
        @apply mb-0 leading-normal;
      }

      p.cn-changes {
        text-decoration: underline;
      }

      .cn-learn-more {
        margin-bottom: 1rem;
        display: inline-block;
        flex-grow: 1;
        @apply text-xs;
      }

      .cn-buttons {
        @apply flex w-full sm:inline-block sm:w-auto space-x-2;

        @media (max-width: $cm-mobile) {
          width: 100%;
        }

        button.cm-btn {
          @media (max-width: $cm-mobile) {
            width: calc(50% - #{$btn-margin-right});
          }
        }
      }

      .cn-ok {
        // we add a margin that cancels out unless the items are wrapped
        // in which case it will add some spacing between them
        margin-top: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: right;
        align-items: baseline;
      }
    }
  }

  .cookie-modal-notice {
    @include modal(400px, 400px);

    padding: 1em;
    padding-top: 0.2em;

    .cn-ok {
      @apply mt-4;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .cookie-notice-hidden {
    @apply hidden;
  }
}
