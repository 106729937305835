// =============================================================================
//    Custom
// =============================================================================
:root {
  --color-primary: #c49a6c;
  --color-secondary: #b91918;
  --color-black: #0f0f0f;
  --swiper-theme-color: #b91918;
  --swiper-navigation-color: #b91918;
}

// =============================================================================
//    TailwindCSS
// =============================================================================
@import 'tailwind';

// =============================================================================
//    Components
// =============================================================================
@import 'components/cookie-consent';
@import 'components/buttons';
@import 'components/header-slideshow';

// =============================================================================
//    Modules
// =============================================================================
// @import "modules/mixins";
// @import "modules/fonts";

// =============================================================================
//    Partials
// =============================================================================
//@import "partials/list";

// =============================================================================
//    Selections
// =============================================================================
::-moz-selection {
  color: #fff;
  background: var(--color-primary);
}

::selection {
  color: #fff;
  background: var(--color-primary);
}

// =============================================================================
//    Global
// =============================================================================
[v-cloak],
[x-cloak] {
  display: none;
}

// =============================================================================
//    Custom
// =============================================================================
@layer base {
  /* General */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-headline font-regular text-black uppercase;

    span {
      @apply block;
    }
  }

  /* Misc */
  .rotated {
    @apply mb-2 lg:mb-0 lg:-rotate-180 font-headline font-extrabold tracking-widest uppercase sm:text-2xl md:text-3xl lg:text-4xl;

    @media (min-width: 1024px) {
      writing-mode: vertical-lr;
      text-orientation: mixed;
    }
  }

  /* Stage */
  #stage {
    @apply text-white font-headline tracking-widest uppercase lg:text-5xl;

    h1 {
      @apply font-extrabold;
    }

    p {
      @apply font-regular;
    }
  }

  /* Footer */
  #footer {
    @apply before:absolute
    before:content-['']
    before:block
    before:w-16
    before:h-8
    before:skew-x-[-30deg]
    before:translate-x-3
    before:sm:translate-x-4
    before:sm:-translate-y-4
    before:border-l-[6px]
    before:border-primary;
  }

  /* Introduction */
  #introduction {
    nav {
      a {
        @apply hover:text-secondary transition-colors;
      }
    }

    h1 {
      @apply text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold tracking-widest uppercase;
    }
  }

  /* RoomInfo */
  #roomInfo {
    .roomFacts {
      @apply text-primary;

      p {
        @apply font-semibold;
      }
    }
  }

  /* BookingMask */
  #bookingMask {
    @apply absolute w-full left-1/2 transform -translate-x-1/2 -translate-y-2/4 text-sm;
  }

  footer {
    ul {
      @apply space-y-2;

      li a {
        @apply text-primary hover:text-secondary transition-colors underline;
      }
    }
  }

  /* Prose */
  .prose {
    @apply max-w-none #{!important};

    // fontSize: {
    //   'sm': ['0.875rem', '1.563rem'],
    //   'base': ['1rem', '1.875rem'],
    //   'lg': ['1.25rem', '1.875rem'],
    //   'xl': ['1.5rem', '2.5rem'],
    //   '2xl': ['2rem', '3rem'],
    //   '3xl': ['2.375rem', '3rem'],
    //   '4xl': ['2.625rem', '3rem'],
    //   '5xl': ['3rem', '4rem'],
    // },

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply font-headline font-regular text-black uppercase;
    }

    h1 {
      @apply text-2xl sm:text-2xl md:text-3xl lg:text-5xl font-extrabold;
    }

    h2 {
      @apply text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold;
    }

    h3 {
      @apply text-base sm:text-2xl md:text-3xl lg:text-2xl;
    }

    h4 {
      @apply text-base sm:text-2xl md:text-3xl lg:text-xl;
    }

    h5 {
      @apply text-base sm:text-2xl md:text-3xl lg:text-xl;
    }

    h6 {
      @apply text-base sm:text-2xl md:text-3xl lg:text-lg;
    }

    a {
      &:hover {
        @apply transition-colors duration-200;
      }
    }
  }
}

.lazyload:not(.transition-none),
.lazyloading:not(.transition-none) {
  opacity: 0;
}

.lazyloaded:not(.transition-none) {
  opacity: 1;
  transition: opacity 250ms;
}

// Floating labels
.floating {
  label {
    @apply transition duration-300 origin-top-left text-gray-400 select-none translate-y-0.5;
  }

  input {
    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      @apply transform scale-75 translate-x-[0.35rem] -translate-y-1 leading-normal;
    }
  }

  textarea {
    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      @apply transform scale-75 translate-x-[0.35rem] leading-normal;
    }
  }

  &.select {
    select {

      ~ label {
        @apply transform scale-75 translate-x-[0.25rem] -translate-y-1;
      }
    }
  }
}

.lang {
  $lang-height: 30px;
  $lang-width: 60px;

  background-color: rgba(#fff, 0.2);
  border-radius: calc($lang-height / 2);
  display: block;
  font-size: 12px;
  height: $lang-height;
  line-height: $lang-height;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transform: translateZ(0); // WebKit fix
  width: $lang-width;

  &:before {
    background-color: #fff;
    border-radius: calc($lang-height / 2);
    content: '';
    height: 100%;
    left: -50%;
    position: absolute;
    top: 0;
    transition: 0.3s left ease;
    width: 100%;
    z-index: 1;
  }

  span {
    color: #fff;
    display: block;
    float: left;
    font-weight: 400;
    height: $lang-height;
    position: relative;
    text-align: center;
    transition: 0.3s color ease;
    width: 50%;
    z-index: 2;
  }

  // Language: german
  @at-root html[lang='de'] & {
    &:before {
      left: -50%;
    }
    &:hover:before {
      left: 50%;
    }
    span.de {
      color: var(--color-primary);
    }

    &:hover {
      span.de {
        color: #fff;
      }
      span.en {
        color: var(--color-primary);
      }
    }
  }

  // Language: english
  @at-root html[lang='en'] & {
    &:before {
      left: 50%;
    }
    &:hover:before {
      left: -50%;
    }
    span.en {
      color: var(--color-primary);
    }

    &:hover {
      span.de {
        color: var(--color-primary);
      }
      span.en {
        color: #fff;
      }
    }
  }
}

.swiper {
  &:before,
  &:after {
    @apply absolute top-0 h-full w-[10%] z-10 opacity-75;
    content: '';
  }

  &:before {
    @apply left-0 bg-gradient-to-r from-black/80 to-transparent;
  }

  &:after {
    @apply right-0 bg-gradient-to-l from-black/80 to-transparent;
  }
}

[class*='ribbon'] {
  &:before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
  }
}

.ribbon-slider {
  @apply before:top-0 before:left-full before:border-b before:border-primary before:transform;

  &:before {
    border-bottom-width: 24px;
    border-right: 24px solid transparent;
  }
}

.ribbon-br {
  @apply before:top-full before:-right-px before:border-t before:border-gray-400 before:transform before:translate-y-px;

  &:before {
    border-top-width: 10px;
    border-right: 10px solid transparent;
  }
}

.ribbon-footer {
  @apply before:top-0 before:left-full before:border-b before:border-gray-500 before:transform;

  &:before {
    border-bottom-width: 16px;
    border-right: 8px solid transparent;
  }
}

.animated-border {
  line {
    stroke-width: 4;
    stroke: #fff;
    fill: none;
    transition: transform 0.7s ease-out;
  }

  line.top,
  line.bottom {
    stroke-dasharray: 330 120;
  }

  line.left,
  line.right {
    stroke-dasharray: 490 200;
  }

  &:hover {
    line.top {
      transform: translateX(-200%);
    }

    line.bottom {
      transform: translateX(200%);
    }

    line.left {
      transform: translateY(200%);
    }

    line.right {
      transform: translateY(-200%);
    }
  }
}

.checkmark {
  @apply block w-56 h-56 rounded-full;
  stroke-width: 2;
  stroke: var(--color-primary);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px var(--color-primary);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  @apply fill-gray-50;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--color-primary);
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--color-primary);
  }
}

.hamburger {
  @apply w-6 h-6;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  &.light span {
    background: #fff;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2), &:nth-child(3) {
      top: 6px;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }

  &.active {
    span:nth-child(1) {
      top: 6px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    span:nth-child(4) {
      top: 6px;
      width: 0%;
      left: 50%;
    }
  }
}
